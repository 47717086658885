import React from "react";
import HeroSection from "@src/components/partials/community-badges/HeroSection";
import BadgesSection from "@src/components/partials/community-badges/BadgesSection";
import { graphql, useStaticQuery } from "gatsby";
import SEO from "../components/SEO";
import { defaultNormalizeStaticQueryResult } from "@src/utils/normalize-static-query-result";

const CommunityBadges = () => {
  const data = useStaticQuery(graphql`
    query {
      directus {
        badges {
          name
          id
          description
          category
          image {
            id
            filename_disk
          }
          poster_pack
          survey_url
          visible_in_page
          modal_heading
          modal_description
          reward
          reward_title
          reward_image {
            id
            filename_disk
          }
        }
        page_community_badges {
          hero_title
          hero_text
          high_achiever_title
          expert_title
          expert_text
          gift_icon {
            id
            filename_download
            filename_disk
          }
          badges_button_text
          claim_badge_button_text
          learn_more_button_text
        }
      }
    }
  `);

  defaultNormalizeStaticQueryResult(data);

  const {
    directus: {
      badges,
      page_community_badges: {
        hero_title,
        hero_text,
        high_achiever_title,
        expert_title,
        expert_text,
        gift_icon,
        badges_button_text,
        claim_badge_button_text,
        learn_more_button_text,
      },
    },
  } = data;

  const HeroSectionProps = {
    hero_title,
    hero_text,
  };

  const BadgesSectionProps = {
    hero_title,
    hero_text,
    high_achiever_title,
    expert_title,
    expert_text,
    content: badges,
    gift_icon,
    badges_button_text,
    claim_badge_button_text,
    learn_more_button_text,
  };

  return (
    <>
      <SEO
        title={data.directus.page_community_badges.hero_title}
        description={data.directus.page_community_badges.hero_text}
        twitter={{
          card: "summary_large_image",
          site: "@classdojo",
          creator: "@classdojo",
          title: data.directus.page_community_badges.hero_title,
          description: data.directus.page_community_badges.hero_text,
        }}
      />
      <HeroSection {...HeroSectionProps} />
      <BadgesSection {...BadgesSectionProps} />
    </>
  );
};

export default CommunityBadges;
